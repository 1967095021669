<template>
	<main>
		<el-dialog :visible="true" @close="close">
			<template slot="title">
				<div class="title">{{query.id ? '编辑' : '创建'}}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px">
					<el-form-item label="姓名" prop="nickname" >
						<el-input v-model="form.nickname" />
					</el-form-item>
					<el-form-item label="账号" prop="username" >
						<el-input v-model="form.username" />
					</el-form-item>
					<el-form-item label="密码" prop="password" >
						<el-input type="password" v-model="form.password" />
						<div class="tip" v-if="query.id">不修改密码时无需输入</div>
					</el-form-item>
					<el-form-item label="手机号码" prop="phone" >
						<el-input v-model="form.phone" />
					</el-form-item>
					<el-form-item label="邮箱" prop="email" >
						<el-input v-model="form.email" />
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-radio-group v-model="form.status">
							<el-radio v-for="(item,index) in column_option.status" :key="index" :label="item.id">{{item.title}}</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">保 存</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {admin as api} from "@/api/admin";
import {MixinCreate} from "@/service/admin/mixinCreate";
export default {
	mixins:[MixinCreate],
	data(){
		return {
			api,
		}
	},
	computed:{
		rules(){
			return {
				nickname:[
					{required:true,message:"请输入昵称"}
				],
				username:[
					{required:true,message:"请输入账号"}
				],
				password:[
					{validator:this.checkPassword}
				]
			}
		}
	},
	methods:{
		checkPassword(rule, value, callback){
			if (!this.query.id && !value) {
				callback(new Error('请输入密码'));
			} else {
				callback();
			}
		}
	}
}
</script>
